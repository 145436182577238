@import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&display=swap");

* {
  scroll-behavior: smooth;
  font-family: "Baloo 2", cursive;
}

a {
  text-decoration: none !important;
  color: #000 !important;
}

/* prosidebar active style start */

.active {
  border-left: 5px solid #7c0000 !important;
  background-color: #7c000010 !important;
  color: #7c0000 !important;
}

/* prosidebar active style end */

::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #65656566;
  border-radius: 10px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
